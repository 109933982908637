import React from "react"
import { Container, Col, Row } from "react-bootstrap"

import Content1 from "./content1"
import Content2 from "./content2"

import ScrollAnimation from "react-animate-on-scroll"

const ContattiPage = ({ lista }) => (
  <>
    <Container fluid>
      <Row className="">
        <Col md={12} lg={12} xl={6} className="pb-5">
          <ScrollAnimation
            duration={0.5}
            animateIn="bounceInLeft"
            animateOut="bounceOutLeft"
          >
            <Content1 />
          </ScrollAnimation>
        </Col>
        <Col className="mb-4">
          <ScrollAnimation
            duration={0.5}
            animateIn="bounceInRight"
            animateOut="bounceOutRight"
          >
            <Content2 />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
    {/* <Row></Row> */}
  </>
)

export default ContattiPage
