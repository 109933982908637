import React from "react"

import { Container } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import FormElement from "../../components/htmls/formElement"

const datiForm = {
  action: "https://mailthis.to/infoarchimedesrl",

  /* hiddens in a map cycle */
  hiddens_names: ["_subject", "_after", "_confirmation", "_honeypot"],
  hiddens_values: [
    "archimede-srl.com Modulo Contatto",
    "http://archimede-srl.com/form-response",
    "Form inviato correttamente, grazie per averci contattato",
    "",
  ],
}

const Content1 = ({ lista }) => (
  <Container>
    <div className="p-5 mt-5 border shadow">
      <div className="pb-4">
        <h3>Per info</h3>
        <h6 className="ml-2 text-secondary">
          <small> * Campi obbligatori</small>
        </h6>
      </div>
      <Form action={datiForm.action} method="POST">
        <Form.Row>
          <FormElement required type="text" name="nome" placeholder="Nome" />
          <FormElement required type="text" name="cognome" placeholder="Cognome" />
        </Form.Row>

        <Form.Row>
          <FormElement required type="email" name="email" placeholder="Email" />
        </Form.Row>

        <Form.Row>
          <FormElement required as="textarea" type="text" name="messaggio" placeholder="Messaggio" />
        </Form.Row>

        {datiForm.hiddens_names.map((element, index) => (
          <Form.Control
            key={index}
            type="hidden"
            name={element}
            value={datiForm.hiddens_values[index]}
          />
        ))}
        <Button variant="primary" type="submit">
          Invia
        </Button>
      </Form>
    </div>
  </Container>
)

export default Content1

/* 
old Form senza FormElement

<Form action={datiForm.action} method="POST">
        <Form.Row>
          <Form.Group as={Col} controlId="validationName">
            <Form.Label>
              Nome <small>*</small>
            </Form.Label>
            <Form.Control required type="text" placeholder="Nome" name="nome" />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="validationLastName">
            <Form.Label>
              Cognome <small>*</small>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Cognome"
              name="cognome"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>
              Email <small>*</small>
            </Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter email"
              name="e-mail"
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridMessage">
            <Form.Label>
              Messaggio <small>*</small>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              type="text"
              placeholder=""
              name="messaggio"
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Control type="hidden" name="_next" value={datiForm.response} />
          <Form.Control
            type="hidden"
            name="_subject"
            value={datiForm.subject}
          />
          <Form.Control
            type="hidden"
            name="_template"
            value={datiForm.template}
          />
        </Form.Row>

        <Button variant="primary" type="submit">
          Invia
        </Button>
      </Form>

*/
