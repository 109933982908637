import React from "react"
import { /*  ResponsiveEmbed, */ Container, Col, Row } from "react-bootstrap"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const Content2 = () => (
  <StaticQuery
    query={graphql`
      {
        file(absolutePath: { regex: "/images/contatti/maps/maps/" }) {
          id
          childImageSharp {
            fluid(jpegQuality: 100, jpegProgressive: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {/* https://goo.gl/maps/11yHy9VoqpUS1DXq8 */}
        <Container>
          <div
            className="my-5 shadow"
            style={{ width: "auto", height: "auto" }}
          >
            <Row className="justify-content-md-center">
              <Col className="">
                <a
                  className="font-weight-light"
                  target="_blank"
                  href="https://goo.gl/maps/11yHy9VoqpUS1DXq8"
                  rel="noopener noreferrer"
                >
                  <Img fluid={data.file.childImageSharp.fluid} alt="" />
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    )}
  />
)

export default Content2
/* <ResponsiveEmbed aspectRatio="21by9">
        <iframe
        title="myFrameMapsContatti"
        style={{ border: 0 }}
        aria-hidden="false"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6331.636538448113!2d14.06300375628997!3d37.48861466520165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3aed6774a9938091!2sArchimede%20s.r.l.%20-%20Societ%C3%A0%20di%20Ingegneria!5e0!3m2!1sit!2sit!4v1586443567072!5m2!1sit!2sit"
        >
        </iframe>
   </ResponsiveEmbed>
*/
/* <iframe src="" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */
